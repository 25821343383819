@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: inherit;
}

.btn {
  display: block;
  background-color: #818c39;
  color: var(--white);
  text-align: center;
  padding: 0.6rem 1.4rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 2rem;
  height: 90px;
  padding: 1rem 3rem;
}

.menu {
  position: relative;
}

.menu-bar li:first-child .dropdown {
  flex-direction: initial;
  min-width: 480px;
}

.menu-bar li:first-child ul:nth-child(1) {
  border-right: 1px solid #eeeeee;
}

.menu-bar li:nth-child(n + 2) ul:nth-child(1) {
  border-bottom: 1px solid #eeeeee;
}

.menu-bar .nav-link:hover,
.dropdown-link:hover {
  color: #720455;
}

.menu-bar,
.right-container,
.right-container .search {
  display: flex;
  align-items: center;
}

.dropdown {
  margin-top: 20px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  min-width: 230px;
  background-color: var(--white);
  border-radius: 10px;
  position: absolute;
  color: #707070;
  top: 36px;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.97) translateX(-5px);
  transition: 0.1s ease-in-out;
  box-shadow: var(--shadow);
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.dropdown.active {
  visibility: visible;
  opacity: 1;
  transform: scale(1) translateX(5px);
}

.dropdown ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.2rem;
  font-size: 0.95rem;
}

.dropdown-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.dropdown-link p {
  font-size: 0.8rem;
  color: #636363;
}

.right-container {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.right-container .search {
  position: relative;
}

.right-container img {
  border-radius: 50%;
}

.search .bx-search {
  position: absolute;
  left: 10px;
  top: 50%;
  font-size: 1.3rem;
  transform: translateY(-50%);
  opacity: 0.6;
}

#hamburger {
  display: none;
  padding: 0.1rem;
  margin-left: 1rem;
  font-size: 1.9rem;
}

.bubble-dot {
  position: relative;
}

.bubble-dot .ant-badge-dot {
  position: absolute;
  top: 0;
  right: 0;
  animation: pulse 4ms infinite;
}

.hero-mobile-menu-button {
  display: none;
}

.hero-mobile-navigation {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 50;
  position: fixed;
  transform: translateX(100%);
  transition: transform 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
  overflow-y: auto;
}

.hero-mobile-navigation.open {
  transform: translateX(0%);
  display: block;
}

body.menu-open {
  overflow: hidden;
}

.hero-links {
  gap: 1px;
  display: flex;
  margin-top: 10px;
  align-items: flex-start;
  flex-direction: column;
}

.button-mobile {
  border-radius: 30px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  font-weight: 500;
  margin: 5px 0;
  width: 340px;
  padding: 0px 0px 0px 0px;
  box-sizing: border-box;
}

.button-mobile1 {
  background-color: #e29094;
  color: #fff;
  border: 1px solid #f5adb2;
}

.button-mobile1:hover {
  background-color: #f5a0a4;
  color: #fff;
  border: 1px solid #d68386;
}

.button-mobile2 {
  background-color: #e29094;
  color: #ffffff;
  border: 1px solid #f5adb2;
}

.button-mobile2:hover {
  background-color: #f5a0a4;
  color: #fff;
  border: 1px solid #d68386;
}

@media (max-width: 1250px) {
  .container {
    padding: 1rem;
  }
}

@media (max-width: 1100px) {
  #hamburger {
    display: block;
  }

  .container {
    padding: 1.2rem;
  }

  .menu {
    display: none;
    position: relative;
    top: 0px;
    left: 0px;
    min-height: auto;
    width: 90vw;
  }

  .menu-bar li:first-child ul:nth-child(1) {
    border-right: none;
    border-bottom: 1px solid #eeeeee;
  }

  .dropdown {
    display: none;
    min-width: 100%;
    border: none !important;
    border-radius: 5px;
    position: static;
    top: 0;
    left: 0;
    visibility: visible;
    opacity: 1;
    transform: none;
    box-shadow: none;
  }

  .menu.show,
  .dropdown.active {
    display: block;
  }

  .dropdown ul {
    padding-left: 0.3rem;
  }

  .menu-bar {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 1rem;
    padding: 0px;
  }

  .menu-bar li:first-child .dropdown {
    min-width: 100%;
  }

  .menu-bar > li:not(:last-child) {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #eeeeee;
  }
}

@media (max-width: 600px) {
  .right-container {
    display: none;
  }

  .hero-mobile-menu-button {
    margin-right: 10px;
    margin-top: 0px;
    display: block;
  }
}
